/* Estilo global para todo o aplicativo */
body {
    margin: 0; /* Remove margens padrão */
    padding: 0; /* Remove preenchimento padrão */
    font-family: 'Arial', sans-serif; /* Define a fonte */
    background-color: #f4f4f4; /* Cor de fundo leve */
}

/* Estilo para o conteúdo das páginas */
.page-container {
    max-width: 1200px; /* Largura máxima do conteúdo */
    margin: 0 auto; /* Centraliza o conteúdo horizontalmente */
    padding: 20px; /* Espaçamento interno nas laterais */
    background-color: white; /* Cor de fundo do conteúdo */
    border-radius: 8px; /* Bordas arredondadas */
    min-height: 81.7vh;
}

a {
  text-decoration: none; /* Remove sublinhado */
  color: #ffffff; /* Cor branca para contraste com o fundo */
  font-weight: bold;
  transition: color 0.3s ease; /* Transição suave para o efeito de hover */
}

a:hover {
  color: #cce7ef; /* Cor mais clara no hover */
  text-decoration: underline;
}

.page-container a.default {
    text-decoration: none; /* Remove o sublinhado */
    color: #682ef1; /* Cor do texto cinza escuro */
    font-weight: bold; /* Deixa o texto mais destacado */
    transition: color 0.3s; /* Transição suave para hover */
}

.page-container a.default:hover {
    color: blue; /* Cinza mais escuro ao passar o mouse */
    text-decoration: underline;
}

/* Estilos responsivos */
@media (max-width: 650px) {
    .page-container {
        padding: 15px; /* Diminui o espaçamento em telas menores */
    }
}
  
  .gastos-candidatos, .gastos-partido, .gastos-fornecedor {
    margin-bottom: 40px;
  }
  
  h3 {
    margin-top: 20px;
    color: #333;
  }

  .tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .tab {
    flex: 1;
    padding: 10px;
    background: #f1f1f1;
    border: 1px solid #ddd;
    cursor: pointer;
    text-align: center;
  }
  
  .tab.active {
    background: #3498db; /* Cor de fundo da aba ativa */
    color: white; /* Texto da aba ativa */
    font-weight: bold; /* Para destacar a aba ativa */
  }
  
  .tab:hover {
    background-color: #107ec7;
  }


  /* Spinner */
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .spinner {
    border: 8px solid #f3f3f3; /* Cor do fundo */
    border-top: 8px solid #3498db; /* Cor da borda de cima */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Animação */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  