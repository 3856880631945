.container {
  background-color: #046381; /* Cor de fundo */
  padding: 26px 10px; /* Espaçamento interno */
  display: flex;
  align-items: center; /* Centraliza o conteúdo */
  text-align: center; /* Centraliza o texto */
  color: #ffffff; /* Texto branco */
  border-top: 3px solid #034c5d; /* Borda superior */
  justify-content: space-between;
  width: 100%;
}

.container p {
  margin: 8px 0;
  font-size: 13px;
}

@media (max-width: 650px) {
  .container {
    display: block;
    text-align: center;
  }
}
