.header {
    text-align: center;
    margin-bottom: 30px;
}

.header h1 {
    color: #046381;
    margin-bottom: 10px;
}

.header p {
    color: #333;
    font-size: 1.1rem;
}

.highlights {
    margin-bottom: 40px;
}

.highlights h2 {
    color: #046381;
    margin-bottom: 20px;
    text-align: center;
}

.cards {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

.card h3 {
    color: #046381;
    margin-bottom: 10px;
}

.card p {
    color: #555;
    margin-bottom: 15px;
}

.linkButton {
    display: inline-block;
    background-color: #046381;
    color: #ffffff;
    padding: 8px 16px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.linkButton:hover {
    background-color: #034c5d;
}

.about {
    background: #e0f7fa;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 40px;
}

.about h2 {
    color: #046381;
    margin-bottom: 10px;
}

.about p {
    color: #333;
    line-height: 1.6;
}