* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  ul {
    list-style: none;
  }
  
  a {
    text-decoration: none;
    color: black;
    font-size: 18px;
  }
  
  /* navbar */
  .navbar {
    background-color: #046381;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    min-height: 70px;
  }

  .logo {
    height: 50px;
    width: 180px;
}
  

  .navMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
  }
  
  @media screen and (max-width: 780px){
    .hamburger {
      display: block;
      cursor: pointer;
    }
  
    .bar {
          background-color: #fff;
          width: 20px;
          height: 3px;
          display: block;
          margin: 5px;
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
      }
  
    /* adding active class to the bars  */
    .hamburger.active .bar:nth-child(2){
      opacity: 0;
    }
    .hamburger.active .bar:nth-child(1){
      transform: translateY(8px) rotate(45deg);
    }
    .hamburger.active .bar:nth-child(3){
      transform: translateY(-8px) rotate(-45deg);
    }
  
    .navMenu{
      position: absolute;
      flex-direction: column;
      gap: 0;
      top: 70px;
      left: -100%;
      text-align: start;
      width: 100%;
      transition: 0.7s ease-in-out;
      background-color: #046381;
      padding: 10px;
      z-index: 100;
    }
  
    .navMenu.active{
      left: 0;
    }
  
    .navMenu li {
      margin: 16px 0;
    }
  }